import { Button, Checkbox, Form, FormInstance, Modal, Row } from "antd";
import { FloatNumberInput } from "../../../../../../../../components/FloatNumberInput";
import { useMemo } from "react";
import { useButtonHandlers } from "./hooks/useButtonHandlers";

type TAssignChecksModalProps = {
  form: FormInstance;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hasAssigned: boolean;
  batchId: string;
  selectedDisbursementIds: React.Key[];
  setSelectedDisbursementIds: React.Dispatch<React.SetStateAction<React.Key[]>>;
  reloadBatch: () => void;
  setShouldDisbOpen: React.Dispatch<
    React.SetStateAction<
      | {
          checkModeOn: boolean;
        }
      | undefined
    >
  >;
  isChecksModeOn: boolean;
};

export const AssignChecksModal = ({
  form,
  isOpen,
  setIsOpen,
  hasAssigned,
  batchId,
  selectedDisbursementIds,
  setSelectedDisbursementIds,
  reloadBatch,
  setShouldDisbOpen,
  isChecksModeOn,
}: TAssignChecksModalProps) => {
  const { isDisabled, onCancelClick, onAssignClick } = useButtonHandlers({
    batchId,
    form,
    setIsOpen,
    selectedDisbursementIds,
    setSelectedDisbursementIds,
    reloadBatch,
    setShouldDisbOpen,
    isChecksModeOn,
  });

  const footer = useMemo(
    () => (
      <Row className="w-100 d-flex justify-end">
        <Button
          shape="round"
          type="primary"
          onClick={onAssignClick}
          disabled={isDisabled}
        >
          Assign
        </Button>
        <Button
          shape="round"
          onClick={onCancelClick}
          className="ml-2"
          disabled={isDisabled}
        >
          Cancel
        </Button>
      </Row>
    ),
    [isDisabled, onAssignClick, onCancelClick]
  );

  const title = useMemo(
    () =>
      selectedDisbursementIds.length === 0
        ? "Assign Check Numbers"
        : `Assign ${selectedDisbursementIds.length} Check Number`,
    [selectedDisbursementIds]
  );

  return (
    <Modal
      title={title}
      open={isOpen}
      footer={footer}
      width={300}
      className="assign-checks-modal"
      closable={true}
      onCancel={onCancelClick}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className="py-4"
        disabled={isDisabled}
      >
        <FloatNumberInput
          name="checkNumber"
          label="Beginning Check Number"
          form={form}
          prefix=""
          allowDecimal={false}
          disabled={isDisabled}
        />
        {hasAssigned && (
          <Form.Item valuePropName="checked" name="ignoreAssigned">
            <Checkbox>Ignore checks with numbers</Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
