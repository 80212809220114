import { FormInstance } from "antd";
import { useEffect, useState } from "react";
import CustomersService from "../../../services/customers";
import { getFormatted } from "../../../lib/PhoneFormatter";
import { formatTaxId } from "../../Customers/hooks/getFormatTaxId";
import { hasAlternateAddress } from "./hasAlternateAddress";
import dayjs from "../../../configs/dayjs";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const useFetchCustomer = (
  selectedItemId: string,
  form: FormInstance,
  setInitialData: (value: any) => void
) => {
  const navigate: NavigateFunction = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(
    selectedItemId !== "create"
  );

  useEffect(() => {
    if (selectedItemId !== "create") {
      CustomersService.get(selectedItemId)
        .then((res: any) => {
          let data: any = { ...res };
          if (res.typeId === 1) {
            data = {
              ...res,
              customerIndividualData: {
                ...res.customerIndividualData,
                dateOfBirth: res.customerIndividualData.dateOfBirth
                  ? dayjs(res.customerIndividualData.dateOfBirth)
                  : undefined,
              },
            };
          }

          if (res.phone) {
            data.phone = getFormatted(res.phone).slice(2);
          }

          if (res.fax) {
            data.fax = getFormatted(res.fax).slice(2);
          }

          data.taxId = formatTaxId(res.taxId, res.typeId);

          if (hasAlternateAddress(res)) {
            delete data.alternativeAddress;
          }

          form.setFieldsValue(data);
          setIsLoading(false);

          setInitialData(data);
        })
        .catch((e) => {
          e.response.data.status === 400 && navigate("/not-found");
          setIsLoading(false);
        });
    } else {
      form.setFieldValue("typeId", 1);
    }
  }, [selectedItemId, form, setInitialData, navigate]);

  return { isLoading };
};
