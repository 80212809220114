import { useCallback, useEffect, useState } from "react";
import PayeeDisbursementsService from "../../../../../../../../../services/accounts/payeeDisbutsements";
import { FormInstance } from "antd";
import {
  DISBURSEMENTS_METHOD_TYPES,
  DISBURSEMENTS_STATUS,
  PAYEE_TYPES,
} from "../../../../../../../../../lib/constants";
import dayjs from "../../../../../../../../../configs/dayjs";

export const useGetInitialValues = (
  id: string | null,
  form: FormInstance,
  setDisbursementMethodState: React.Dispatch<
    React.SetStateAction<{
      isEscrowAcc: boolean;
      isACHMethod: boolean;
    }>
  >
) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const fetchDataAndFillTheForm = useCallback(() => {
    if (id !== null) {
      PayeeDisbursementsService.fetch(id)
        .then((res: any) => {
          const initialValues = {
            ...res,
            payeeId: res.payeeNumber,
            type: res.type === 101 ? "Impound Distribution" : res.type,
            alternativePayeeId: res.alternativePayeeNumber,
            beginningDate:
              res.beginningDate !== null ? dayjs(res.beginningDate) : null,
            endDate: res.endDate !== null ? dayjs(res.endDate) : null,
            amountSwitcher: res.percentage || res.amount === null ? 2 : 1,
            status:
              res.enableForPayments === false
                ? DISBURSEMENTS_STATUS[0].value
                : res.status,
          };

          const { percentage } = initialValues;

          if (initialValues.percentage) {
            initialValues.percentage = percentage.toFixed(4);
          }
          setIsLoading(false);

          form.setFieldsValue(initialValues);

          setDisbursementMethodState({
            isEscrowAcc: res.method === 8,
            isACHMethod: res.method >= 5 && res.method <= 7,
          });
        })
        .catch(() => setIsLoading(false));
    } else {
      const initialValues = {
        enableForPayments: true,
        type: PAYEE_TYPES[0].value,
        method: DISBURSEMENTS_METHOD_TYPES[0].value,
        status: DISBURSEMENTS_STATUS[0].value,
        amountSwitcher: 1,
      };

      form.setFieldsValue(initialValues);
    }
  }, [form, id, setDisbursementMethodState]);

  useEffect(() => fetchDataAndFillTheForm(), [fetchDataAndFillTheForm]);

  return { isLoading };
};
