import { FormInstance } from "antd";
import dayjs from "../../../../../configs/dayjs";
import { useCallback, useMemo } from "react";

export const useSetPayment = (
  form: FormInstance,
  setInstance: React.Dispatch<React.SetStateAction<any>>,
  setInitialData: React.Dispatch<React.SetStateAction<any>>
) => {
  const dateFields = useMemo(
    () => [
      "date",
      "dueDateAfter",
      "dueDateNext",
      "interestDateFrom",
      "interestDateTo",
    ],
    []
  );

  const getFormatted = useCallback(
    (data: any): any => {
      const newData = { ...data };

      dateFields.forEach((item: string) => {
        newData[item] = newData[item] ? dayjs(newData[item]) : null;
      });

      if (!newData.breakdown.interestToBalance) {
        newData.breakdown.interestToBalance = null;
      }

      if (!newData.numberPayments) {
        newData.numberPayments = null;
      }

      return newData;
    },
    [dateFields]
  );

  const setForm = useCallback(
    (data: any, isFormBlured: boolean) => {
      if (data && !isFormBlured) {
        const newData = getFormatted(data);

        setInstance(newData);

        form.setFieldsValue(newData);
      }
    },
    [form, getFormatted, setInstance]
  );

  const resetInitial = useCallback(
    (data: any) => {
      const newData = getFormatted(data);

      setInitialData({ payment: newData, warnings: [] });
    },
    [getFormatted, setInitialData]
  );

  return { setForm, resetInitial };
};
