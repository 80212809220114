import { ColumnsType } from "antd/es/table";
import dayjs from "../../../../../../../configs/dayjs";
import { useMemo } from "react";
import { DATE_FORMAT } from "../../../../../../../lib/constants";
import { Row, Tooltip } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useFilters } from "./useFilters";

export const useTableColumns = () => {
  const { filters } = useFilters();

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Sent Date",
        dataIndex: "sentDate",
        key: "sentDate",
        width: "15%",
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        width: "52%",
        ellipsis: true,
        render: (value: string) => {
          return (
            <Row className="w-100">
              <Tooltip placement="top" title="Email">
                <MailOutlined className="mr-3" />
              </Tooltip>
              <p className="my-0 elipsis">{value}</p>
            </Row>
          );
        },
      },
      {
        title: "Receiver",
        dataIndex: "receiverName",
        key: "receiverName",
        width: "33%",
        ellipsis: true,
        filters,
        render: (value: string, record: any) => {
          const { receiverEmail } = record;

          return (
            <Row className="d-block w-100">
              <p className="my-0 text-md elipsis">{value}</p>
              <p className="my-0 text-sm title elipsis">{receiverEmail}</p>
            </Row>
          );
        },
      },
    ],
    [filters]
  );

  return { columns };
};
