import { Button, Dropdown, MenuProps } from "antd";
import { useMemo } from "react";
import { DownOutlined } from "@ant-design/icons";

type TPrintDropdownProps = {
  isDisabled: boolean;
};

export const PrintDropdown = ({ isDisabled }: TPrintDropdownProps) => {
  const items: MenuProps["items"] = useMemo(
    () => [
      { key: 1, label: <div>Checks</div> },
      { key: 2, label: <div>Direct Deposit Advices (Savings)</div> },
      { key: 3, label: <div>Direct Deposit Advices (Checkings)</div> },
      { key: 4, label: <div>Sent to Escrow Accounts Advices</div> },
    ],
    []
  );

  return (
    <Dropdown menu={{ items }} trigger={["click"]} disabled={isDisabled}>
      <Button shape="round">
        Print <DownOutlined />
      </Button>
    </Dropdown>
  );
};
