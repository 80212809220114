import { Dropdown, MenuProps } from "antd";
import dayjs from "../../../configs/dayjs";
import { ColumnsType } from "antd/es/table";
import { DATE_FORMAT, COPIED_ACC_NUMBER } from "../../../lib/constants";
import { useMemo, useCallback, MouseEvent } from "react";
import { useGetRole } from "../../../lib/hooks/getRole";
import { EllipsisOutlined } from "@ant-design/icons";
import { useDefaultDeleteModal } from "../../../lib/hooks/defaultDeleteModal";
import { deleteContract } from "../../../acions/accounts/contracts";
import { useStatusTag } from "./statusTag";
import { getFormattedCurrency } from "../../../lib/currencyFormatter";
import { Link, useSearchParams } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";
import { useGetColumnSortValue } from "../../../lib/hooks/useGetColumnSortValue";
import { CopyButton } from "../../../components/CopyButton";

export const useAccountsTableColumns = (
  reloadData: () => void,
  navigateToAccount: (id: string) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const { getStatus } = useStatusTag();

  const { openDeleteModal } = useDefaultDeleteModal(deleteContract, reloadData);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setSearchParams] = useSearchParams();

  const { getColumnSortValue } = useGetColumnSortValue();

  const onSortChange = (sorter: any, request: any) => {
    const newRequest = { ...request };
    if (!sorter.order) {
      delete newRequest["order"];
    }

    if (sorter.field === "accountNumber") {
      if (sorter.order === "ascend") {
        newRequest["order"] = 0;
      }

      if (sorter.order === "descend") {
        newRequest["order"] = 1;
      }
    }

    if (sorter.field === "status") {
      if (sorter.order === "ascend") {
        newRequest["order"] = 2;
      }

      if (sorter.order === "descend") {
        newRequest["order"] = 3;
      }
    }

    setSearchParams(newRequest);
  };

  const onEdit = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      navigateToAccount(e.currentTarget.id);
    },
    [navigateToAccount]
  );

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      openDeleteModal(e.currentTarget.id, "Delete account");
    },
    [openDeleteModal]
  );

  const columns = useMemo(() => {
    const value: ColumnsType<any> = [
      {
        title: `Account ${isAdmin ? "Number" : "#"}`,
        dataIndex: "accountNumber",
        key: "accountNumber",
        render: (value: string, record: any) => {
          const { id, accountNumber } = record;

          return (
            <>
              <Link
                to={`${ROUTES.ACCOUNTS}/${isAdmin ? id : accountNumber}`}
                className="cell-elipsis"
                title={value}
                onClick={(e) => e.stopPropagation()}
              >
                <span className="link-btn">{value}</span>
                {record.xRef && (
                  <div className="text-sm title">{record.xRef}</div>
                )}
              </Link>
              {isAdmin && (
                <CopyButton value={value} message={COPIED_ACC_NUMBER} />
              )}
            </>
          );
        },
        sorter: true,
        sortOrder: (() => getColumnSortValue("0", "1"))(),
        width: "13%",
      },
      {
        title: "Buyer",
        dataIndex: "buyer",
        key: "buyer",
        width: `${isAdmin ? "16" : "17"}%`,
        ellipsis: true,
        render: (value: string) => (
          <div className="elipsis" title={value}>
            {value}
          </div>
        ),
        responsive: ["xl"],
      },
      {
        title: "Seller",
        dataIndex: "seller",
        key: "seller",
        width: `${isAdmin ? "15" : "17"}%`,
        ellipsis: true,
        render: (value: string) => (
          <div className="elipsis" title={value}>
            {value}
          </div>
        ),
        responsive: ["xl"],
      },
      {
        title: "Property Address",
        dataIndex: "propertyAddress",
        key: "propertyAddress",
        width: `${isAdmin ? "15" : "18"}%`,
        ellipsis: true,
        render: (value: string) => (
          <div className="elipsis" title={value}>
            {value || "-"}
          </div>
        ),
        responsive: ["xl"],
      },
      {
        title: "Last Payment",
        dataIndex: "lastPaymentDate",
        key: "lastPaymentDate",
        render: (value: string) =>
          value ? dayjs(value).format(DATE_FORMAT) : "-",
        width: "10%",
        responsive: ["xl"],
      },
      {
        title: "Next Payment",
        dataIndex: "nextPayment",
        key: "nextPayment",
        align: "right",
        render: (value: any, record: any) => {
          const paymentDate = isAdmin
            ? record.nextPaymentDate
            : value.paymentDate;

          const amount = isAdmin ? record.nextPaymentAmount : value.amount;

          const dateToDisplay = paymentDate
            ? dayjs(paymentDate).format(DATE_FORMAT)
            : "-";

          const isPast = dayjs().isAfter(dayjs(paymentDate));

          const displayAmount: string = getFormattedCurrency(amount || 0);

          return (
            <div className="next-payment-col d-flex justify-end">
              <div className="elipsis" title={displayAmount}>
                {displayAmount}
              </div>
              <div className="date-val">
                &nbsp;to&nbsp;
                <span className={isPast ? "error" : ""}>{dateToDisplay}</span>
              </div>
            </div>
          );
        },
        width: `${isAdmin ? "15" : "16"}%`,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value: string | number) => getStatus(value),
        sorter: true,
        sortOrder: (() => getColumnSortValue("2", "3"))(),
        width: "9%",
      },
    ];

    isAdmin &&
      value.push({
        title: "Action",
        render: (_: any, record: any) => {
          const { status, id } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div id={id} onClick={onEdit}>
                  Edit
                </div>
              ),
            },
          ];

          if (status === 8) {
            items.push({
              key: "2",
              label: (
                <div id={id} onClick={onDelete}>
                  Delete
                </div>
              ),
            });
          }

          return (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          );
        },
      });

    return value;
  }, [isAdmin, getColumnSortValue, getStatus, onEdit, onDelete]);

  return { columns, onSortChange };
};
