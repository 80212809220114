import { Button, Radio, RadioChangeEvent, Row } from "antd";
import { BATCH_DISBURSEMENTS_FILTER_OPTIONS } from "../../../../../../../lib/constants";
import { useCallback } from "react";
import { ModeDropdown } from "./ModeDropdown";
import { PrintDropdown } from "./PrintDropdown";

type TCommonModeProps = {
  onMethodChange: (value: number) => void;
  isDisabled: boolean;
  onAssignClick: () => void;
  onOpenChecksModeClick: () => void;
};

export const CommonModeHeader = ({
  onMethodChange,
  isDisabled,
  onAssignClick,
  onOpenChecksModeClick,
}: TCommonModeProps) => {
  const onRadioChange = useCallback(
    (e: RadioChangeEvent) => {
      onMethodChange(e.target.value);
    },
    [onMethodChange]
  );

  return (
    <Row className="w-100 d-flex justify-space-between">
      <Radio.Group
        options={BATCH_DISBURSEMENTS_FILTER_OPTIONS}
        optionType="button"
        onChange={onRadioChange}
        defaultValue={0}
      />
      <Row>
        <ModeDropdown
          isDisabled={isDisabled}
          onOpenChecksModeClick={onOpenChecksModeClick}
        />
        <Button
          shape="round"
          className="mr-4"
          onClick={onAssignClick}
          disabled={isDisabled}
        >
          Assign Check#
        </Button>
        <PrintDropdown isDisabled={isDisabled} />
      </Row>
    </Row>
  );
};
