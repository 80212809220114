import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import MailingsService from "../../services/accounts/mailings";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import { MAIL_SETTINGS_WAS_SAVED } from "../../lib/constants";

export const saveSettings =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await MailingsService.saveSettings(request);

      dispatch(setSuccessMessage(MAIL_SETTINGS_WAS_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };
