import { FormInstance } from "antd";
import { useEffect, useState } from "react";
import MailingsService from "../../../../../../../services/accounts/mailings";

export const useFetchSettings = (
  parentId: string | undefined,
  form: FormInstance
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (parentId) {
      MailingsService.fetchSettings(parentId)
        .then((res: any) => {
          setIsLoading(false);

          form.setFieldsValue(res);
        })
        .catch(() => setIsLoading(false));
    }
  }, [parentId, form]);

  return { isLoading };
};
