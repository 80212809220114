import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../../../../store";
import { saveSettings } from "../../../../../../../acions/accounts/mailings";
import { FormInstance } from "antd";

export const useSave = (
  form: FormInstance,
  contractId: string | undefined,
  reloadData: () => void,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSave = useCallback(async () => {
    try {
      setIsDisabled(true);

      const request = await form.validateFields();

      await Promise.resolve(dispatch(saveSettings({ ...request, contractId })));

      setIsDisabled(false);
      setIsOpen(false);
      reloadData();
    } catch {
      setIsDisabled(false);
    }
  }, [contractId, dispatch, form, reloadData, setIsOpen]);

  return { isDisabled, onSave };
};
