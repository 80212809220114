import { API_URL } from "../configs/apiUrl";
import authAxios, { authHeader } from "./data";

const data = [
  {
    id: "1",
    date: "2020-05-25T00:00:00-06:00",
    subject: "Payment Advice",
    receiverName: "John Week",
    contractNumber: 53235686,
    email: "jWeek@gmail.com",
  },
  {
    id: "2",
    date: "2023-01-20T00:00:00-06:00",
    subject: "Payment Advice",
    receiverName: "Albert Flores",
    email: "A.Flor@gmail.com",
    contractNumber: 53235687,
    status: "error",
  },
  {
    id: "3",
    date: "2024-05-09T00:00:00-06:00",
    subject: "Payment Advice",
    receiverName: "Cameron Williamson",
    email: "CameraWe@gmail.com",
    contractNumber: 53235688,
    status: "warning",
  },
  {
    id: "4",
    date: "2022-05-29T00:00:00-06:00",
    subject: "Payment Receipt",
    receiverName: "Alexander Cameron",
    email: "Alexander.Camerun@gmail.com",
    contractNumber: 53235689,
    status: "processing",
  },
];

const fetchAll = async (request: any) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(data);
    }, 500);
  });
};

const fetchCount = async (request: any) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(4);
    }, 500);
  });
};

const sendOne = async (id: string) => {
  const response = await authAxios.put(
    `${API_URL}DomainEmails/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(true);
    }, 500);
  });
};

const removeAll = async () => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(true);
    }, 500);
  });
};

const EmailsService = {
  fetchAll,
  fetchCount,
  sendOne,
  remove,
  removeAll,
};

export default EmailsService;
