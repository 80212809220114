import { Col, Row, Tooltip } from "antd";
import { useStatusTag } from "../../../Accounts/hooks/statusTag";
import { ReactNode, useCallback, useMemo } from "react";
import { PaymentRestrictionsRow } from "../BeforeReadModal/PaymentRestrictionsRow";
import { ITicklerExtended } from "../../../../types/Tickler";
import { Ticklers } from "./Ticklers";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";
import dayjs from "../../../../configs/dayjs";
import { DATE_FORMAT } from "../../../../lib/constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ROUTES from "../../../../configs/routesConfig";

type AccountDataProps = {
  ticklers: ITicklerExtended[] | null;
  data: any | null;
};

export const AccountData = ({ ticklers, data }: AccountDataProps) => {
  const { getStatus } = useStatusTag();

  const accountData: any = useMemo(() => {
    if (data) {
      return {
        ...data.contract,
        dueDateAfter: data.dueDateAfter.format(),
        dueDateNext: data.dueDateNext.format(),
        isFirstPayment: data.isFirstPayment,
      };
    }

    return {};
  }, [data]);

  const regularAmount: string = useMemo(() => {
    return getFormattedCurrency(accountData.regularAmount || 0);
  }, [accountData]);

  const getDateValue = useCallback(
    (name: string): string => {
      if (!accountData[name]) {
        return "-";
      }

      return dayjs(accountData[name]).format(DATE_FORMAT);
    },
    [accountData]
  );

  const getRow = (label: string, value: ReactNode): ReactNode => {
    return (
      <Row className="align-top w-100 mt-4">
        <Col span={12}>
          <p className="text-md title my-0">{label}</p>
        </Col>
        <Col span={12}>{value}</Col>
      </Row>
    );
  };

  return (
    <Row className="mt-4 w-100 d-block">
      <Row className="align-center w-100">
        <Col span={12}>
          <Link
            to={`${ROUTES.ACCOUNTS}/${data?.contractId}`}
            target="_blank"
            className="customer-link elipsis"
          >
            {" "}
            <h4 className="h h4 ant-btn-link mr-4">
              {accountData.contractNumber}
            </h4>
          </Link>
        </Col>
        <Col span={12}>{getStatus(accountData.status)}</Col>
      </Row>
      {getRow(
        "Primary Payer",
        <Row className="align-center primary-payer-row">
          <Link
            to={`${ROUTES.CUSTOMERS}/${accountData.primaryPayerId}`}
            target="_blank"
            className="customer-link elipsis"
          >
            <span
              className="text-md ant-btn-link w-500"
              title={accountData.primaryPayerName}
            >
              {accountData.primaryPayerName}
            </span>
          </Link>
          {!accountData.primaryPayerTaxId && (
            <Tooltip
              placement="top"
              title="Tax ID is not specified"
              overlayClassName="text-sm"
            >
              <ExclamationCircleOutlined className="search-icon logo-cursor ml-2" />
            </Tooltip>
          )}
        </Row>
      )}
      {getRow(
        "Regular Amount",
        <p className="text-md w-500 my-0 elipsis" title={regularAmount}>
          {regularAmount}
        </p>
      )}
      {getRow(
        "Next Due Date",
        <Row className="d-block">
          <p className="text-md w-500 my-0">{getDateValue("dueDateNext")}</p>
          {accountData.isFirstPayment && (
            <p className="my-0 text-sm w-500 card-header">1st payment</p>
          )}
        </Row>
      )}
      {getRow(
        "Due Date After the Payment",
        <p className="text-md w-500 my-0">{getDateValue("dueDateAfter")}</p>
      )}
      {getRow(
        "Balance Due Date",
        <p className="text-md w-500 my-0">{getDateValue("balanceDueDate")}</p>
      )}
      <Row className="mt-8">
        <PaymentRestrictionsRow
          paymentRestrictions={accountData.paymentRestrictions}
        />
      </Row>
      <Row className="d-block">
        <Row className="mb-4">
          <p className="my-0 text-md title">TICKLERS</p>
        </Row>
        <Ticklers ticklers={ticklers} />
      </Row>
    </Row>
  );
};
