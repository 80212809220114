import dayjs from "../../../configs/dayjs";

export const getFormatted = (value: any): any => {
  if (value) {
    let data: any = { ...value };

    const { taxId, phone, fax } = value;

    data.taxId = taxId ? taxId.split("-").join("") : undefined;

    data.phone = phone ? `1${phone.replace(/[\s-()]/g, "")}` : undefined;

    data.fax = fax ? `1${fax.replace(/[\s-()]/g, "")}` : undefined;

    const { typeId } = value;

    if (typeId === 1) {
      data = {
        ...data,
        customerIndividualData: {
          ...value.customerIndividualData,
          dateOfBirth: value.customerIndividualData.dateOfBirth
            ? dayjs(value.customerIndividualData.dateOfBirth).format()
            : undefined,
        },
      };
    }

    return data;
  }

  return null;
};
