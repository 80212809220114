import { DatePicker } from "antd";
import dayjs from "../../../configs/dayjs";
import { DATE_FORMAT } from "../../../lib/constants";
import { useSearchParams } from "react-router-dom";

const { RangePicker } = DatePicker;

type TBatchDatePickerProps = {
  request: {
    pageSize: number;
    pageNumber: number;
    from: string;
    to: string;
  };
};

export const BatchDatePicker = ({ request }: TBatchDatePickerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const onChange = (value: any) => {
    if (value !== null) {
      const from = dayjs(value[0]).format(DATE_FORMAT);
      const to = dayjs(value[1]).format(DATE_FORMAT);

      setSearchParams({ from, to });
    }
  };

  return (
    <div>
      Batch Date
      <RangePicker
        className="ml-2"
        value={[dayjs(request?.from), dayjs(request?.to)]}
        onChange={onChange}
        format={DATE_FORMAT}
      />
    </div>
  );
};
