import { FormInstance } from "antd";
import dayjs from "../../../../../configs/dayjs";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../store";
import { updateTotal } from "../../../../../acions/accounts/lateCharges";

export const useUpdate = (
  data: any,
  form: FormInstance,
  accountId: string,
  reloadData: () => void
) => {
  const dispatch = useAppDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const { lateChargeLastAssessed, lateChargeAssessedCalculatedTo } = data;

      form.setFieldsValue({
        lateChargeLastAssessed: lateChargeLastAssessed
          ? dayjs(lateChargeLastAssessed)
          : undefined,
        lateChargeAssessedCalculatedTo: lateChargeAssessedCalculatedTo
          ? dayjs(lateChargeAssessedCalculatedTo)
          : undefined,
      });
    }
  }, [data, form]);

  const onChange = () => {
    const formData = form.getFieldsValue();

    const { lateChargeLastAssessed, lateChargeAssessedCalculatedTo } = formData;

    const request = {
      lateChargeLastAssessed: lateChargeLastAssessed
        ? dayjs(lateChargeLastAssessed).startOf("D").format()
        : undefined,
      lateChargeAssessedCalculatedTo: lateChargeAssessedCalculatedTo
        ? dayjs(lateChargeAssessedCalculatedTo).startOf("D").format()
        : undefined,
    };

    setIsDisabled(true);

    Promise.resolve(dispatch(updateTotal(request, accountId)))
      .then(() => {
        setIsDisabled(false);
        reloadData();
      })
      .catch(() => setIsDisabled(false));
  };

  return {
    onChange,
    isDisabled,
  };
};
