import { Button, Checkbox, Form, FormInstance, Row } from "antd";
import { CustomModal } from "../../../lib/CustomModal";
import { useCallback, useMemo } from "react";
import { BatchDateInput } from "./BatchDateInput";
import dayjs from "../../../configs/dayjs";
import { useCreateEmails } from "../hooks/useCreateEmails";

type CreateEmailsModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

export const CreateEmailsModal = ({
  isModalOpen,
  setIsModalOpen,
}: CreateEmailsModalProps) => {
  const batchDate = useMemo(() => dayjs(), []);

  const [form]: FormInstance[] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      paymentSubject: true,
      batchDate,
      lateNotice: true,
    }),
    [batchDate]
  );

  const { isLoading, create, batchDateError, setBatchDateError } =
    useCreateEmails(form, batchDate, setIsModalOpen);

  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      titleText="Create Emails"
      width={400}
      modalClass="create-modal"
      headerClass="text w-500"
      destroyOnClose={true}
    >
      <Row className="pt-4 pb-4 create-emails-modal">
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={initialValues}
          disabled={isLoading}
        >
          <Form.Item
            name="paymentSubject"
            className="mb-0 w-100"
            valuePropName="checked"
          >
            <Checkbox>Payment Receipts and Payment Advices</Checkbox>
          </Form.Item>

          <Row className="d-block position-relative">
            <BatchDateInput
              form={form}
              value={initialValues.batchDate}
              setBatchDateError={setBatchDateError}
            />

            {batchDateError && (
              <span className="text-sm error">{batchDateError}</span>
            )}
          </Row>

          <Form.Item
            name="lateNotice"
            className="mb-6 w-100"
            valuePropName="checked"
          >
            <Checkbox>Late Notices</Checkbox>
          </Form.Item>
        </Form>

        <span className="text-sm title pt-3 mb-4">
          Emails with Payment Documents and Late Notices will be created for
          subscribed customers only (Account {">"} Mailings).
        </span>

        <Row className="justify-end pt-6 w-100">
          <Button
            type="primary"
            shape="round"
            className="mr-2"
            onClick={create}
          >
            Create Emails
          </Button>
          <Button shape="round" className="mr-2" onClick={closeModal}>
            Cancel
          </Button>
        </Row>
      </Row>
    </CustomModal>
  );
};
