import { Col, DatePicker, Form, FormInstance, Radio, Row, Select } from "antd";
import {
  DATE_FORMAT,
  PAYMENT_TYPES,
  PAYMENT_METHODS,
  INTEREST_CALCULATION_METHODS,
} from "../../../../lib/constants";
import { FloatNumberInput } from "../../../../components/FloatNumberInput";
import { useCallback, useEffect, useMemo, useRef } from "react";
import dayjs from "../../../../configs/dayjs";
import { Rule } from "antd/es/form";

type GeneralProps = {
  form: FormInstance;
  instance: any;
  isFormBlured: boolean;
  calculate: (toUpdate?: { name: string; value: any }) => void;
};

export const General = ({
  form,
  instance,
  isFormBlured,
  calculate,
}: GeneralProps) => {
  const type = Form.useWatch(["type"], { form });

  const amountRef = useRef<any>(null);

  useEffect(() => {
    if (!isFormBlured) {
      const { current } = amountRef;

      current && current.inputElement.focus();
    }
  }, [amountRef, isFormBlured]);

  const interestDateFrom: string = useMemo(() => {
    return instance && instance.interestDateFrom
      ? dayjs(instance.interestDateFrom).format(DATE_FORMAT)
      : "-";
  }, [instance]);

  const interestCalculationMethod: string = useMemo(() => {
    if (instance) {
      const value = INTEREST_CALCULATION_METHODS.find(
        (item: any) =>
          item.value === instance.contract.interestCalculationMethod
      );

      return value ? value.label : "-";
    }

    return "-";
  }, [instance]);

  const pureCalculate = useCallback(() => calculate(), [calculate]);

  const dateRule: Rule[] = useMemo(
    () => [
      {
        type: "date",
        required: true,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const onCloseDate = useCallback(
    (show: boolean) => !show && calculate({ name: "date", value: true }),
    [calculate]
  );

  const onCloseInterestToDate = useCallback(
    (show: boolean) => {
      !show && calculate();
    },
    [calculate]
  );

  return (
    <Row>
      <Col span={12} className="pr-5">
        <Row>
          <Form.Item name="type" label="Type" className="mb-4">
            <Radio.Group
              options={PAYMENT_TYPES}
              optionType="button"
              onChange={pureCalculate}
            />
          </Form.Item>
        </Row>
        <Row className="align-center">
          <FloatNumberInput
            name="amount"
            label="Amount"
            form={form}
            className={`${type === 2 ? "w-100" : "amount-input pr-2"} mb-4`}
            onPressEnter={calculate}
            customRef={amountRef}
          />
          {type !== 2 && (
            <FloatNumberInput
              name="numberPayments"
              label="# Payments"
              className="payment-number-input mb-4"
              form={form}
              allowDecimal={false}
              prefix=""
              onPressEnter={calculate}
            />
          )}
        </Row>
        <Row>
          <Form.Item name="date" label="Date" className="mb-4" rules={dateRule}>
            <DatePicker
              className="date-picker mr-2"
              format={DATE_FORMAT}
              onOpenChange={onCloseDate}
            />
          </Form.Item>
          <FloatNumberInput
            name="holdDays"
            label="Hold Days"
            className="payment-number-input mb-4"
            form={form}
            allowDecimal={false}
            prefix=""
            onPressEnter={calculate}
          />
        </Row>
      </Col>
      <Col span={12} className="pl-5">
        <Row>
          <Form.Item name="method" label="Method" className="mb-4 w-100">
            <Select options={PAYMENT_METHODS} onSelect={calculate} />
          </Form.Item>
        </Row>
        <Row className="w-100">
          <Col span={12}>
            <p className="my-0 text-md">Interest From</p>
            <p className="my-0 text-md mt-2">{interestDateFrom}</p>
          </Col>
          <Col span={12}>
            <Form.Item
              name="interestDateTo"
              label="Interest To"
              className="mb-4"
            >
              <DatePicker
                format={DATE_FORMAT}
                onOpenChange={onCloseInterestToDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="my-0 text-md">Method</p>
            <p className="my-0 text-md mt-2">{interestCalculationMethod}</p>
          </Col>
          <Col span={12}>
            <FloatNumberInput
              name="interestAmount"
              label="Interest Amount"
              className="mb-4"
              form={form}
              onPressEnter={calculate}
              allowNegative
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
