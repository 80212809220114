import { useCallback, useEffect, useState } from "react";
import { FormInstance } from "antd";
import dayjs from "../../../../../../../configs/dayjs";
import { useCalculateInterestPerDiem } from "./calculateInterestPerDiem";
import ContractsService from "../../../../../../../services/accounts/contracts";
import { ILegal } from "../../../../../../../types/Legal";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const useFetchAccount = (contractId: string, form: FormInstance) => {
  const navigate: NavigateFunction = useNavigate();

  const [data, setData] = useState<any>(null);

  const [legalData, setLegalData] = useState<ILegal | null>(null);

  const { calculate } = useCalculateInterestPerDiem(form);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setDataDates = useCallback(
    (data: any, value: string | undefined, prop: string) => {
      data[prop] = value ? dayjs(value) : undefined;
    },
    []
  );

  const fetchData = useCallback((): void => {
    setIsLoading(true);

    ContractsService.fetch(contractId)
      .then((res: any) => {
        const data = { ...res };

        const {
          nextDueDate,
          interestCalculationDate,
          beginningDate,
          balanceDueDate,
          interestCalculationMethod,
          paymentSchedule,
          nextAutochargeDate,
          paymentMethod,
          autoChargeEnabled,
          nextAutoChargeDate,
          autoChargeMethodType,
          securingPropertyAddress,
          numberOfSecuringProperties,
          propertyAddress,
          propertyDescription,
          isSecuringPropertyAddressTheSame,
        } = data;

        if (data.status === 7) {
          delete data.status;
        }

        setDataDates(data, nextAutoChargeDate, "nextAutoChargeDate");

        setDataDates(data, nextDueDate, "nextDueDate");

        setDataDates(data, beginningDate, "beginningDate");

        setDataDates(data, balanceDueDate, "balanceDueDate");

        data.interestCalculationDate = interestCalculationDate
          ? dayjs(interestCalculationDate)
          : data.beginningDate;

        if (!paymentSchedule) {
          data.paymentSchedule = 12;
        }

        if (!interestCalculationMethod && interestCalculationMethod !== 0) {
          data.interestCalculationMethod = 1;
        }

        if (!paymentMethod && paymentMethod !== 0) {
          data.paymentMethod = 1;
        }

        if (!autoChargeMethodType) {
          data.autoChargeMethodType = 1;
        }

        if (!autoChargeEnabled) {
          data.autoChargeEnabled = false;
        }

        setDataDates(data, nextAutochargeDate, "nextAutochargeDate");

        form.setFieldsValue(data);

        data.interestPerDiem = calculate();

        setLegalData({
          securingPropertyAddress,
          numberOfSecuringProperties,
          propertyAddress,
          propertyDescription,
          isSecuringPropertyAddressTheSame,
        });

        setData(data);
        setIsLoading(false);
      })
      .catch((e) => {
        e.response.data.status === 400 && navigate("/not-found");
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, contractId, setDataDates]);

  useEffect(() => {
    contractId && fetchData();
  }, [contractId, fetchData]);

  return { data, isLoading, setData, fetchData, legalData };
};
