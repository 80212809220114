import { DatePicker, Form, FormInstance, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import { DATE_FORMAT } from "../../../lib/constants";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";

type BatchDateInputProps = {
  form: FormInstance;
  value: Dayjs;
  setBatchDateError: React.Dispatch<React.SetStateAction<string>>;
};

export const BatchDateInput = ({
  form,
  value,
  setBatchDateError,
}: BatchDateInputProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [date, setDate] = useState<Dayjs | null>(value);

  const onValueClicked = useCallback(() => setIsEdit(true), []);

  const onChange = useCallback(
    (e: Dayjs | null) => {
      setDate(e);
      setBatchDateError("");
    },
    [setBatchDateError]
  );

  const datePickerStatus: undefined | "error" = useMemo(
    () => (date ? undefined : "error"),
    [date]
  );

  const onSave = useCallback(() => {
    if (!datePickerStatus) {
      form.setFieldValue("batchDate", date);
      setIsEdit(false);
    }
  }, [date, form, datePickerStatus]);

  const onCloseEdit = useCallback(() => {
    if (!datePickerStatus) {
      setIsEdit(false);
    }
  }, [datePickerStatus]);

  return (
    <Row className="align-center batch-prop ml-6 mb-6 editable">
      <p className="my-0 text-md title w-500">Batch Date: </p>
      <Row
        className={`batch-label align-center value ${isEdit ? "hidden" : ""}`}
        onClick={onValueClicked}
      >
        <p className="my-0 px-1 text-md title w-500">
          {date?.format(DATE_FORMAT)}
        </p>
        <EditOutlined className="ml-1" />
      </Row>
      <Row
        className={`batch-datapicker align-center position-absolute ${
          !isEdit ? "hidden" : ""
        }`}
      >
        <Form.Item name="batchDate" className="mb-0 w-100">
          <Row>
            <DatePicker
              size="small"
              className="ml-1"
              format={DATE_FORMAT}
              value={date}
              status={datePickerStatus}
              onChange={onChange}
            />
            <CheckOutlined
              className="ml-2 control logo-cursor"
              onClick={onSave}
            />
            <CloseOutlined
              className="ml-2 control logo-cursor"
              onClick={onCloseEdit}
            />
          </Row>
        </Form.Item>
      </Row>
    </Row>
  );
};
