import { ColumnsType } from "antd/es/table";
import { ReactNode, useCallback, useMemo, MouseEvent } from "react";
import { DATE_FORMAT } from "../../../lib/constants";
import dayjs from "../../../configs/dayjs";
import { Button, Dropdown, MenuProps, Row, Tooltip } from "antd";
import { SendOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useStatusLabel } from "./useStatusLabel";
import { useSendOne } from "./useSendOne";
import { useDelete } from "./useDelete";

export const useTableColumns = (type: 1 | 2, reloadData: () => void) => {
  const { getStatusLabel } = useStatusLabel();

  const { isSending, send } = useSendOne(reloadData);

  const { remove } = useDelete(reloadData);

  const onSend = useCallback(
    (e: MouseEvent<any>) => {
      const id = e.currentTarget.id;
      send(id);
    },
    [send]
  );

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      remove(e.currentTarget.id);
    },
    [remove]
  );

  const columns: ColumnsType<any> = useMemo(() => {
    const cols = [
      {
        title: "Created Date",
        dataIndex: "date",
        key: "date",
        width: "14%",
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        width: "32%",
        ellipsis: true,
        render: (value: string) => value,
      },
      {
        title: "Account",
        dataIndex: "contractNumber",
        key: "contractNumber",
        width: "13%",
        ellipsis: true,
        render: (value: string) => value,
      },
      {
        title: "Receiver",
        dataIndex: "receiverName",
        key: "receiverName",
        width: "31%",
        ellipsis: true,
        render: (value: string, record: any) => {
          const { email, status } = record;

          return (
            <Row className="justify-space-between align-center w-100">
              <Row className="d-block">
                <p className="my-0 text-md elipsis">{value}</p>
                <p className="my-0 text-sm title elipsis">{email}</p>
              </Row>
              {type === 1 && status && getStatusLabel(status)}
            </Row>
          );
        },
      },
    ];

    type === 1 &&
      cols.push({
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: "10%",
        ellipsis: true,
        render: (_: any, record: any) => {
          const { status, id } = record;

          const disabled = status === "processing" || status === "warning";

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <Row id={id} onClick={onDelete}>
                  Delete
                </Row>
              ),
            },
          ];

          const sendBtn: ReactNode = disabled ? (
            <Tooltip placement="top" title="Fix invalid email first">
              <Button
                className="mr-4"
                type="text"
                icon={<SendOutlined />}
                disabled={disabled || isSending}
              />
            </Tooltip>
          ) : (
            <Button
              className="mr-4"
              type="text"
              icon={<SendOutlined />}
              disabled={disabled || isSending}
              id={id}
              onClick={onSend}
            />
          );

          return (
            <Row>
              {sendBtn}
              <Dropdown menu={{ items }} trigger={["click"]}>
                <EllipsisOutlined />
              </Dropdown>
            </Row>
          );
        },
      });

    return cols;
  }, [getStatusLabel, type, onSend, isSending, onDelete]);

  return { columns };
};
