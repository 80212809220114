const data = [
  {
    id: "1",
    sentDate: "2020-05-25T00:00:00-06:00",
    subject: "Payment Advice",
    receiverName: "John Week",
    receiverEmail: "jWeek@gmail.com",
  },
  {
    id: "2",
    sentDate: "2023-01-20T00:00:00-06:00",
    subject: "Payment Advice",
    receiverName: "Albert Flores",
    receiverEmail: "A.Flor@gmail.com",
  },
  {
    id: "3",
    sentDate: "2024-05-09T00:00:00-06:00",
    subject: "Payment Advice",
    receiverName: "Cameron Williamson",
    receiverEmail: "CameraWe@gmail.com",
  },
  {
    id: "4",
    sentDate: "2022-05-29T00:00:00-06:00",
    subject: "Payment Receipt",
    receiverName: "Alexander Cameron",
    receiverEmail: "Alexander.Camerun@gmail.com",
  },
  {
    id: "5",
    sentDate: "2022-12-29T00:00:00-06:00",
    subject: "Payment Receipt",
    receiverName: "Jerome Bell",
    receiverEmail: "JeroBe@gmail.com",
  },
  {
    id: "6",
    sentDate: "2023-07-29T00:00:00-06:00",
    subject: "Payoff Quote as of 04/04/2024",
    receiverName: "Jake Summers",
    receiverEmail: "Js@gmail.com",
  },
];

const settings = {
  paymentReceiptEmail: true,
  paymentReceiptMail: false,
  paymentAdvicePrimaryEmail: false,
  paymentAdvicePrimaryMail: true,
  paymentAdviceSecondaryEmail: true,
  paymentAdviceSecondaryMail: true,
  lateNoticePayersEmail: true,
  lateNoticePayersSendAfter: 15,
  lateNoticePayeesEmail: false,
};

const fetchAll = async (request: any) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(data);
    }, 500);
  });
};

const fetchCount = async (request: any) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(6);
    }, 500);
  });
};

const fetchSettings = async (contractId: string) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(settings);
    }, 500);
  });
};

const saveSettings = async (request: any) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(true);
    }, 500);
  });
};

const MailingsService = {
  fetchAll,
  fetchCount,
  fetchSettings,
  saveSettings,
};

export default MailingsService;
