import { FormInstance } from "antd";
import { useCallback, useEffect, useState } from "react";
import PayersService from "../../../../../../../../../services/accounts/payers";
import dayjs from "../../../../../../../../../configs/dayjs";

export const useGetInitialValue = (
  id: string | null | undefined,
  form: FormInstance
) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const getInitialData = useCallback(() => {
    if (!id) {
      form.setFieldsValue({
        status: 0,
        trackFor1098: true,
      });
    } else {
      PayersService.fetch(id).then((res: any) => {
        const formData = {
          ...res,
          payeeId: res.payerNumber,
          assumedDate: res.assumedDate ? dayjs(res.assumedDate) : null,
          soldDate: res.soldDate ? dayjs(res.soldDate) : null,
        };

        setIsLoading(false);

        form.setFieldsValue(formData);
      });
    }
  }, [form, id]);

  useEffect(() => getInitialData(), [getInitialData]);

  return { isLoading };
};
