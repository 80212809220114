import { Button } from "antd";
import { useCallback, useState } from "react";
import { CreateEmailsModal } from "./CreateEmailsModal";

type CreateEmailsBtnProps = {
  isLoading: boolean;
};

export const CreateEmailsBtn = ({ isLoading }: CreateEmailsBtnProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = useCallback((): void => setIsModalOpen(true), []);

  return (
    <>
      <Button
        shape="round"
        type="primary"
        disabled={isLoading}
        onClick={openModal}
      >
        Create Emails
      </Button>

      {isModalOpen && (
        <CreateEmailsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
