import { FormInstance } from "antd";
import dayjs from "../../../../../configs/dayjs";
import { useCallback, useState } from "react";
import BatchPaymentsService from "../../../../../services/processing/payments";

export const useCalculate = (
  form: FormInstance,
  instance: any,
  isFormBlured: boolean,
  setForm: (data: any, isFormBlured: boolean) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  manualUpdated: {
    principalAmount: boolean;
    interestAmount: boolean;
    disbursements: boolean;
  },
  setManualUpdated: React.Dispatch<
    React.SetStateAction<{
      principalAmount: boolean;
      interestAmount: boolean;
      disbursements: boolean;
    }>
  >
) => {
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const formatDate = (value: Date): string | null => {
    return value ? dayjs(value).startOf("D").format() : null;
  };

  const calculate = useCallback(
    async (toUpdate?: { name: string; value: any }) => {
      const manualUpdatedVal = { ...manualUpdated };

      if (toUpdate && toUpdate.name === "disbursements") {
        manualUpdatedVal.disbursements = true;
      }

      try {
        let res = await form.validateFields();

        const { date, interestDateTo } = res;

        const { numberPayments, type } = res;

        if (type !== 2 && !numberPayments) {
          res.numberPayments = 1;
        }

        res = {
          ...res,
          date: formatDate(date),
          interestDateTo: formatDate(interestDateTo),
          batchId: instance.batchId,
          contractId: instance.contractId,
          breakdown: {
            ...res.breakdown,
            lateCharge:
              toUpdate && toUpdate.name === "breakdown"
                ? toUpdate.value
                : instance.breakdown.lateCharge,
            interestAfter: +instance.breakdown.interestAfter,
          },
          disbursements:
            toUpdate && toUpdate.name === "disbursements"
              ? toUpdate.value
              : instance.disbursements,
          manualUpdated: manualUpdatedVal,
        };

        if (toUpdate && toUpdate.name === "date") {
          res.updatedField = toUpdate.name;
        }

        setIsLoading(true);

        const calculated = await BatchPaymentsService.calculate(res);

        setHasChanges(true);

        setManualUpdated(manualUpdatedVal);

        setIsLoading(false);
        setForm(calculated, isFormBlured);

        return 1;
      } catch {
        setIsLoading(false);

        return 0;
      }
    },
    [
      form,
      instance,
      setIsLoading,
      setForm,
      isFormBlured,
      manualUpdated,
      setManualUpdated,
    ]
  );

  return { calculate, hasChanges, setHasChanges };
};
