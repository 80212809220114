import { Divider, Form, FormInstance, Row } from "antd";
import { Loader } from "../../../../../../../components/Loader";
import { useFetchSettings } from "../hooks/useFetchSettings";
import { SettingsRow } from "./SettingsRow";
import "../styles.scss";
import { FormFooterButtons } from "./FormFooterButtons";
import { useSave } from "../hooks/useSave";

type MailingSettingsFormProps = {
  parentId?: string;
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MailingSettingsForm = ({
  parentId,
  reloadData,
  setIsOpen,
}: MailingSettingsFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { isLoading } = useFetchSettings(parentId, form);

  const { isDisabled, onSave } = useSave(form, parentId, reloadData, setIsOpen);

  return (
    <Row className="mail-settings-form h-100 d-flex position-relative flex-column">
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <>
          <Form
            form={form}
            className="pa-6"
            layout="vertical"
            requiredMark={false}
            disabled={isDisabled}
          >
            <Row className="my-2 text-md w-500">
              <span className="type-col px-2">Type/Reciever</span>
              <span className="email-col px-2 border-left">Email</span>
              <span className=" mail-col px-2 border-left">Mail</span>
            </Row>

            <Divider className="mt-0 mb-3" />

            <Row className="pa-2">
              <p className="my-0 text-md title">PAYMENT RECIEPT</p>
            </Row>

            <SettingsRow
              form={form}
              label="All Payers"
              emailName="paymentReceiptEmail"
              mailName="paymentReceiptMail"
            />

            <Divider className="mt-0 mb-3" />

            <Row className="pa-2">
              <p className="my-0 text-md title">PAYMENT ADVICE</p>
            </Row>

            <SettingsRow
              form={form}
              label="Primary Payee"
              emailName="paymentAdvicePrimaryEmail"
              mailName="paymentAdvicePrimaryMail"
            />

            <SettingsRow
              form={form}
              label="Secondary Payees"
              emailName="paymentAdviceSecondaryEmail"
              mailName="paymentAdviceSecondaryMail"
            />

            <Divider className="mt-0 mb-3" />

            <Row className="pa-2 justify-space-between align-center">
              <p className="my-0 text-md title">LATE NOTICE</p>
              <p className="my-0 text-sm title send-after-label">Send After</p>
            </Row>

            <SettingsRow
              form={form}
              label="All Payers"
              emailName="lateNoticePayersEmail"
              mailName="lateNoticePayersSendAfter"
              sendAfter
            />

            <SettingsRow
              form={form}
              label="All Payees"
              emailName="lateNoticePayeesEmail"
              mailName="lateNoticePayeesSendAfter"
              sendAfter
            />
          </Form>

          <FormFooterButtons
            isDisabled={isDisabled}
            setIsOpen={setIsOpen}
            onSave={onSave}
          />
        </>
      )}
    </Row>
  );
};
