import { Row, Tabs, TabsProps } from "antd";
import { useFetchDetails } from "./hooks/useFetchDetails";
import { Header } from "./Header";
import { Loader } from "../../../../components/Loader";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubHeader } from "./Header/SubHeader";
import { useOnCreatePayment } from "./hooks/useOnCreatePayment";
import { PaymentsTable } from "./components/PaymentsTable";
import { CreatePaymentAutoModal } from "./components/CreatePaymentAutoModal";
import { DisbursementTable } from "./components/DisbursementsTable";

type TBatchDetailsProps = {
  id: string;
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BatchDetails = ({
  id,
  setIsOpenEditModal,
}: TBatchDetailsProps) => {
  const [activeKey, setActiveKey] = useState<string>("1");

  const [shouldDisbOpen, setShouldDisbOpen] = useState<
    { checkModeOn: boolean } | undefined
  >(undefined);

  const { data, setData, isLoading, fetchData } = useFetchDetails(
    id,
    setActiveKey
  );

  const [isCreatePaymentAutoOpen, setIsCreatePaymentAutoOpen] =
    useState<boolean>(false);

  const isWarehouse: boolean = useMemo(
    () => (data ? data.type === 3 : false),
    [data]
  );

  const { onCreatePayment } = useOnCreatePayment(
    data,
    setIsCreatePaymentAutoOpen
  );

  const onChangeTab = useCallback((key: string) => {
    setActiveKey(key);
    setShouldDisbOpen(undefined);
  }, []);

  const tabItems: TabsProps["items"] = useMemo(() => {
    const tabs: TabsProps["items"] = [
      {
        key: "2",
        label: "Disbursements",
        children: (
          <DisbursementTable
            batchId={id}
            reloadBatch={fetchData}
            setShouldDisbOpen={setShouldDisbOpen}
            shouldDisbOpen={shouldDisbOpen}
          />
        ),
      },
    ];

    !isWarehouse &&
      tabs.unshift({
        key: "1",
        label: "Payments",
        children: (
          <PaymentsTable
            batchId={id}
            reloadBatch={fetchData}
            batchData={data}
          />
        ),
      });

    return tabs;
  }, [id, fetchData, shouldDisbOpen, isWarehouse, data]);

  useEffect(() => {
    shouldDisbOpen && setActiveKey("2");
  }, [shouldDisbOpen]);

  return (
    <>
      {isLoading ? (
        <Row className="processing-view__container justify-center loader">
          <Loader fontSize="64px" />
        </Row>
      ) : (
        <Row className="d-block logged-in__page processing-view__container">
          {data && (
            <Row className="d-block h-100">
              <Header
                data={data}
                setIsOpenEditModal={setIsOpenEditModal}
                openCreatePayment={onCreatePayment}
                isWarehouse={isWarehouse}
                fetchData={fetchData}
              />
              <SubHeader
                data={data}
                setData={setData}
                isWarehouse={isWarehouse}
              />
              <Row className="batch-tabs">
                <Tabs
                  items={tabItems}
                  className="h-100"
                  activeKey={activeKey}
                  onTabClick={onChangeTab}
                />
              </Row>
            </Row>
          )}
          <CreatePaymentAutoModal
            batchId={id}
            isOpen={isCreatePaymentAutoOpen}
            setIsOpen={setIsCreatePaymentAutoOpen}
            onSuccess={fetchData}
          />
        </Row>
      )}
    </>
  );
};
