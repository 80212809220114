import { useCallback, useEffect, useMemo, useState } from "react";
import TicklersTypeService from "../../../../../../../services/administration/ticklers";
import {
  ITicklerExtended,
  ITicklerTypeExtended,
} from "../../../../../../../types/Tickler";
import TicklersService from "../../../../../../../services/accounts/ticklers";
import dayjs from "../../../../../../../configs/dayjs";

type TInitialValuesProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  id: string | null;
};

const initialTypesRequest = {
  pageSize: 100000,
  pageNumber: 1,
};

export const useGetInitialValues = ({
  setIsLoading,
  id,
}: TInitialValuesProps) => {
  const [types, setTypes] = useState<ITicklerTypeExtended[]>();

  const [options, setOptions] = useState<{ value: string; label: string }[]>();

  const [entry, setEntry] = useState<ITicklerExtended | null>(null);

  const [isTickleTypesLoading, setIsTicklerTypesLoading] =
    useState<boolean>(false);

  const fetchTicklerTypes = useCallback(() => {
    setIsTicklerTypesLoading(true);
    TicklersTypeService.fetchAll(initialTypesRequest)
      .then((res: any) => {
        const result = res.map((record: ITicklerTypeExtended) => ({
          label: record.typeName,
          value: record.id,
        }));
        setTypes(res);
        setOptions(result);
        setIsTicklerTypesLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [setIsLoading]);

  const fetchTickler = useCallback(() => {
    if (id !== null) {
      setIsLoading(true);
      TicklersService.fetch(id)
        .then((res: any) => {
          setEntry(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [id, setIsLoading]);

  useEffect(() => {
    fetchTickler();
    fetchTicklerTypes();
  }, [id, fetchTickler, fetchTicklerTypes]);

  const initialValues = useMemo(() => {
    return entry !== null
      ? {
          date: dayjs(entry.date),
          ticklerTypeId: entry.ticklerTypeId,
          text: entry.text,
          paymentAlert: entry.paymentAlert,
        }
      : {
          date: dayjs(),
          ticklerTypeId: options ? options[0].value : "",
          text: types ? types[0].defaultText : "",
          paymentAlert: false,
        };
  }, [entry, options, types]);

  return { options, types, initialValues, isTickleTypesLoading };
};
