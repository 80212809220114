import { ColumnFilterItem } from "antd/es/table/interface";
import { useMemo } from "react";

export const useFilters = () => {
  const filters: ColumnFilterItem[] = useMemo(
    () => [
      {
        text: "Payment Receipts",
        value: "Payment Receipts",
      },
      {
        text: "Payment Advices",
        value: "Payment Advices",
      },
      {
        text: "Late Notices",
        value: "Late Notices",
      },
      {
        text: "Other Mailings",
        value: 0,
      },
    ],
    []
  );

  return { filters };
};
