import { Button, Dropdown, MenuProps } from "antd";
import { Radio, RadioChangeEvent, Row } from "antd";
import { useCallback, useMemo } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { SingleSelect } from "../../../components/SingleSelect";
import { CreateEmailsBtn } from "./CreateEmailsBtn";
import { useDeleteAll } from "../hooks/useDeleteAll";

type HeaderProps = {
  request: any;
  isLoading: boolean;
  setRequest: React.Dispatch<React.SetStateAction<any>>;
  reloadData: () => void;
};

export const Header = ({
  request,
  isLoading,
  setRequest,
  reloadData,
}: HeaderProps) => {
  const { removeAll } = useDeleteAll(reloadData);

  const typeOptions = useMemo(
    () => [
      { value: 1, label: "Outbox" },
      { value: 2, label: "Sent" },
    ],
    []
  );

  const dropdownItems: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <Row onClick={removeAll}>Delete All from Outbox</Row>,
      },
    ],
    [removeAll]
  );

  const sentFilterOptions = useMemo(
    () => [
      { label: "Today", value: 1 },
      { label: "Last Week", value: 2 },
      { label: "Last Month", value: 3 },
      { label: "Last 6 Months", value: 4 },
    ],
    []
  );

  const onFilterChange = useCallback(
    (value: number) => {
      setRequest((prev: any) => ({ ...prev, sentOn: value }));
    },
    [setRequest]
  );

  const onTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      const value = e.target.value;

      const request: any = {
        pageNumber: 1,
        pageSize: 20,
        type: value,
      };

      if (value === 2) {
        request.sentOn = 1;
      }

      setRequest(request);
    },
    [setRequest]
  );

  return (
    <Row className="d-block width-100 mx-8 ">
      <Row className="my-6">
        <h3 className="h h3">Emails</h3>
      </Row>
      <Row className="justify-space-between">
        <Radio.Group
          optionType="button"
          options={typeOptions}
          defaultValue={1}
          onChange={onTypeChange}
          disabled={isLoading}
        />
        {request.type === 1 ? (
          <Row>
            <Dropdown
              className="mr-2"
              menu={{ items: dropdownItems }}
              trigger={["click"]}
              disabled={isLoading}
            >
              <Button shape="circle" icon={<MoreOutlined />}></Button>
            </Dropdown>
            <Button shape="round" className="mr-2" disabled={isLoading}>
              Send All
            </Button>
            <CreateEmailsBtn isLoading={isLoading} />
          </Row>
        ) : (
          <SingleSelect
            options={sentFilterOptions}
            label="Sent"
            value={request.sentOn || undefined}
            onChange={onFilterChange}
            loading={isLoading}
          />
        )}
      </Row>
    </Row>
  );
};
