import { Button, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useDefaultFormSidebar } from "../../../../../../../lib/hooks/defaultFormSidebar";
import { MailingSettingsForm } from "./MailingSettingsForm";
import { useCallback } from "react";
import { createPortal } from "react-dom";

type HeaderProps = {
  contractId: string;
  reloadData: () => void;
};

export const Header = ({ contractId, reloadData }: HeaderProps) => {
  const { formWrapper, isOpen, setIsOpen } = useDefaultFormSidebar({
    id: null,
    setId: () => {},
    reloadData,
    title: "Mailing Settings",
    parentId: contractId,
    FormElem: MailingSettingsForm,
  });

  const onSettingsClicked = useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <Row className="w-100 d-flex justify-space-between">
      <h5 className="h h5 ant-table-title">Sent Mailings</h5>
      <Button
        color="primary"
        type="link"
        icon={<SettingOutlined />}
        onClick={onSettingsClicked}
      >
        Mailing Settings
      </Button>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
