import { Card, Row, Table } from "antd";
import MailingsService from "../../../../../../services/accounts/mailings";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { TRequest } from "../Ticklers/types";
import { useTableColumns } from "./hooks/useTableColumns";
import { useDefaultTableScrollProp } from "../../../../../Customers/hooks/defaultTableScrollProp";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useCallback } from "react";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import TicklersService from "../../../../../../services/accounts/ticklers";
import { Header } from "./components/Header";

type MailingsProps = {
  contractId: string;
};

export const Mailings = ({ contractId }: MailingsProps) => {
  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    setRequest,
    fetchCount,
  } = useFetchDataCount<{ ContractId: string }>(TicklersService, {
    ContractId: contractId,
  });

  const { data, isLoading, fetchData } = useFetchData<TRequest, any>(
    MailingsService,
    request
  );

  const { columns } = useTableColumns();

  const { scroll } = useDefaultTableScrollProp(data, 235);

  const onChange = useCallback(
    (_: any, filters: any) => {
      const { receiverName } = filters;

      setRequest((prev: any) => ({
        ...prev,
        subject: receiverName,
      }));
    },
    [setRequest]
  );

  const reloadData = useCallback((): void => {
    fetchData();
    fetchCount();
  }, [fetchCount, fetchData]);

  return (
    <Row className="w-100 px-8 py-6">
      <Card className="w-100">
        <Header reloadData={reloadData} contractId={contractId} />
        <Row className="w-100 mt-3 d-block table__container">
          <Table
            dataSource={data}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            columns={columns}
            className="w-100 clickable-rows-table"
            scroll={scroll}
            onChange={onChange}
          />
          {total > 20 && (
            <TablePagination
              isTotalLoading={isCountLoading}
              total={total}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
              onPageChange={onPageChange}
            />
          )}
        </Row>
      </Card>
    </Row>
  );
};
